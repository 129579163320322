import React from "react"
import { Link } from "react-router-dom";

export default function ServicesExpertise(){

    const[subMenuIsActive, setSubMenuIsActive] = React.useState(false);

    function toggleSubMenu(){
        setSubMenuIsActive(previous => !previous);
    }

     // Add a click event listener to the document to handle clicks outside of the menu
    React.useEffect(() => {

        function closeMenuOnClickOutside(event) {
            if (subMenuIsActive && !document.querySelector('.subPage--header--menu').contains(event.target)) {
                setSubMenuIsActive(false);
            }
        }

        document.addEventListener('click', closeMenuOnClickOutside);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('click', closeMenuOnClickOutside);
        };

    }, [subMenuIsActive]);
    
    
    return(

        <div>
            <div className="subPage--header">
                <h1>Nos Services</h1>
                <div className="subPage--header--menu" onClick={toggleSubMenu}>
                    <p>Nos Services</p>
                    <div className={subMenuIsActive ? "subPage--header--menu--arrow--up" : "subPage--header--menu--arrow--down"}></div>
                    <ul className={subMenuIsActive ? "subPage--header--menu--list--visible" : "subPage--header--menu--list--hidden"}>
                        <li><Link to="/services/expertise">Expertise Comptable</Link></li>
                        <li><Link to="/services/entreprise">Reprise et creation d'entreprise</Link></li>
                        <li><Link to="/services/fiscalite">Fiscalité</Link></li>
                        <li><Link to="/services/secretariat">Secrétariat juridique</Link></li>
                        <li><Link to="/services/gestion">Gestion de la paie / assistance sociale</Link></li>
                        <li><Link to="/services/audit">Audit légal et audit contractuel</Link></li>
                        <li><Link to="/services/evaluation">Evaluation</Link></li>
                    </ul>
                </div>
            </div>
            <div className="subPage--content">
                <h2>Expertise comptable</h2>
                <p>La comptabilité est une matière qui permet de fournir des états financiers d’une entreprise, en continu, en mettant en place un système d’organisation des données.</p>
                <p>Le rôle de l’expert-comptable est de pouvoir accompagner ses clients afin de présenter des états financiers fiables afin de répondre aux obligations légales et de pouvoir fournir une information de qualité à l’ensemble des parties prenantes de l’entreprise.</p>
                <ul>
                    <li>TENUE DE COMPTABILITÉ</li>
                    <p>Nous gérons votre comptabilité et vos déclarations fiscales. Nous nous chargeons de l’élaboration de vos comptes annuels le cas échéant.</p>
                    
                    <li>RÉVISION</li>
                    <p>Nous intervenons, selon une périodicité à définir, pour réviser la tenue de votre comptabilité élaborée par vos équipes.</p>

                    <li>GESTION</li>
                    <p>Nous vous assistons dans la mise en place d’outils de gestion afin de suivre les performances de votre entreprise : tableaux de bord/reporting, budget de trésorerie, etc…</p>

                    <li>ETABLISSEMENT DE COMPTES CONSOLIDES</li>
                    <p>L’établissement de comptes consolidés peut provenir d’une volonté de l’entreprise afin de disposer d’une vision d’ensemble des comptes d’un groupe de sociétés, ou d’une obligation légale. Nous disposons des équipes en interne afin d’établir ou auditer avec rigueur les documents spécifiques relatifs à l’établissement de comptes consolidés.</p>
                </ul>
            </div>
            
            
        </div>

    )

}