import React from "react"
import { Link } from "react-router-dom";

export default function ServicesAudit(){

    const[subMenuIsActive, setSubMenuIsActive] = React.useState(false);

    function toggleSubMenu(){
        setSubMenuIsActive(previous => !previous);
    }

     // Add a click event listener to the document to handle clicks outside of the menu
    React.useEffect(() => {

        function closeMenuOnClickOutside(event) {
            if (subMenuIsActive && !document.querySelector('.subPage--header--menu').contains(event.target)) {
                setSubMenuIsActive(false);
            }
        }

        document.addEventListener('click', closeMenuOnClickOutside);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('click', closeMenuOnClickOutside);
        };
        
    }, [subMenuIsActive]);
    
    return(

        <div>
            <div className="subPage--header">
                <h1>Nos Services</h1>
                <div className="subPage--header--menu" onClick={toggleSubMenu}>
                    <p>Nos Services</p>
                    <div className={subMenuIsActive ? "subPage--header--menu--arrow--up" : "subPage--header--menu--arrow--down"}></div>
                    <ul className={subMenuIsActive ? "subPage--header--menu--list--visible" : "subPage--header--menu--list--hidden"}>
                        <li><Link to="/services/expertise">Expertise Comptable</Link></li>
                        <li><Link to="/services/entreprise">Reprise et création d'entreprise</Link></li>
                        <li><Link to="/services/fiscalite">Fiscalité</Link></li>
                        <li><Link to="/services/secretariat">Secrétariat juridique</Link></li>
                        <li><Link to="/services/gestion">Gestion de la paie / assistance sociale</Link></li>
                        <li><Link to="/services/audit">Audit légal et audit contractuel</Link></li>
                        <li><Link to="/services/evaluation">Evaluation</Link></li>
                    </ul>
                </div>
            </div>
            
            <div className="subPage--content">
                <h2>Audit légal et contractuel</h2>
                <p>Le commissariat aux comptes, ou contrôle légal des comptes selon la terminologie européenne, est une profession réglementée et indépendante qui contribue à la qualité et à la transparence de l&#39;information financière et comptable émise par les entités contrôlées.</p>
                <p>Les missions exercées par le commissaire aux comptes dans les entreprises et les structures des secteurs associatif, syndical et public reposent sur une obligation légale. Les organisations qui font contrôler leurs comptes alors qu&#39;elles n&#39;y sont pas soumises par la loi expriment, ce faisant, une volonté de transparence, indispensable au bon fonctionnement des échanges et à la confiance.</p>
                <p>Les entités font appel à un commissaire aux comptes pour une mission de certification des comptes. Elles peuvent également lui demander d&#39;autres interventions comme, par exemple, attester des comptes intermédiaires avant une demande d&#39;emprunt, donner une opinion sur des comptes prévisionnels, fournir des attestations pour l&#39;obtention de subventions, faire un audit d&#39;acquisition avant une éventuelle transaction.</p>
                <p>Notre cabinet est inscrit sur la liste des commissaires aux comptes de la région EST et nous avons la capacité d’établir des missions d’audit légal auprès de votre entité (association, PME, ETI).</p>
                <p>Dans le cadre de l’évolution juridique de votre société, nous avons la capacité de vous assister dans les missions suivantes :</p>

                <ul>
                    <li>Commissariat aux apports et à la fusion</li>
                    <li>Commissariat à la transformation</li>
                    <li>Commissariat ad hoc</li>
                </ul>
            </div>
            
        </div>
        
    )

}