import React from "react";
import handshake01 from "../pictures/handshake01.jpg";
import reims01 from "../pictures/Reims01.jpg";
import euroCoin from "../pictures/euroCoin.jpg";
import digital from "../pictures/digital02.jpg";
import oldLogo from "../pictures/decimale_oldLogo.jpg";
import arnaudPic from "../pictures/arnaudCohen.jpg";



export default function Cabinet(){

    // Code to animate the top section on scroll --------------------------------------------------
    const [pageTopVisible, setPageTopVisible] = React.useState(false);
    const pageTopRef = React.useRef();
    const flagRefTop = React.useRef(false);


    // Code to animate the arnaud div on scroll --------------------------------------------------
    const [arnaudVisible, setArnaudVisible] = React.useState(false);
    const arnaudRef = React.useRef();
    const flagRefArnaud = React.useRef(false);

    //code to animate the 1993 picture on scroll -------------------------------------------------
    const [y1993Visible, setY1993Visible] = React.useState(false);
    const y1993Ref = React.useRef();
    const flagRefY1993 = React.useRef(false);


    //code to animate the 2001 picture on scroll -------------------------------------------------
    const [y2001Visible, setY2001Visible] = React.useState(false);
    const y2001Ref = React.useRef();
    const flagRefY2001 = React.useRef(false);


    //code to animate the 2023 picture on scroll -------------------------------------------------
    const [y2023Visible, setY2023Visible] = React.useState(false);
    const y2023Ref = React.useRef();
    const flagRefY2023 = React.useRef(false);


    React.useEffect(() => {

        // page top
        const pageTopObserver = new IntersectionObserver((entries) => {
            let entry = entries[0];
            if (!flagRefTop.current && entry.isIntersecting) {
                flagRefTop.current = true;
                setPageTopVisible(true);
            }
        }, {
            threshold: 0.5
        })
        const currentPageTop = pageTopRef.current;
        pageTopObserver.observe(currentPageTop);


        // arnaud div
        const arnaudObserver = new IntersectionObserver((entries) => {
            let entry = entries[0];
            if (!flagRefArnaud.current && entry.isIntersecting) {
                flagRefArnaud.current = true;
                setArnaudVisible(true);
            }
        }, {
            threshold: 0.3
        })
        const currentArnaud = arnaudRef.current;
        arnaudObserver.observe(currentArnaud);

        // y1993 div
        const y1993Observer = new IntersectionObserver((entries) => {
            let entry = entries[0];
            if (!flagRefY1993.current && entry.isIntersecting) {
                flagRefY1993.current = true;
                setY1993Visible(true);
            }
        }, {
            threshold: 0.5
        })
        const currentY1993 = y1993Ref.current;
        y1993Observer.observe(currentY1993);

        // y2001 div
        const y2001Observer = new IntersectionObserver((entries) => {
            let entry = entries[0];
            if (!flagRefY2001.current && entry.isIntersecting) {
                flagRefY2001.current = true;
                setY2001Visible(true);
            }
        }, {
            threshold: 0.5
        })
        const currentY2001 = y2001Ref.current;
        y2001Observer.observe(currentY2001);

        // y2023 div
        const y2023Observer = new IntersectionObserver((entries) => {
            let entry = entries[0];
            if (!flagRefY2023.current && entry.isIntersecting) {
                flagRefY2023.current = true;
                setY2023Visible(true);
            }
        }, {
            threshold: 0.5
        })
        const currentY2023 = y2023Ref.current;
        y2023Observer.observe(currentY2023);


        return () => {
            pageTopObserver.unobserve(currentPageTop);
            arnaudObserver.unobserve(currentArnaud);
            y1993Observer.unobserve(currentY1993);
            y2001Observer.unobserve(currentY2001);
            y2023Observer.unobserve(currentY2023);
        };

    }, [])

    return ( 
        <div className="page">
            <div className="page--top" id="pageTop02" ref={pageTopRef} style={{backgroundImage: `url(${handshake01})`}}>
                <div className="page--top--titles">
                    <h1>
                        <span className={pageTopVisible ? "page--top--mainTitle" : "page--top--mainTitle page--top--mainTitle--hidden"}>NOTRE CABINET</span>
                        <br/>
                        <span className={pageTopVisible ? "page--top--subtitle" : "page--top--subtitle page--top--subtitle--hidden"}>Une équipe d'experts dédiée à vos projets</span>
                        <br/>
                        <span className={pageTopVisible ? "page--top--subtitle" : "page--top--subtitle page--top--subtitle--hidden"}>depuis plus de 30 ans</span>
                    </h1>
                </div>
                <a className={pageTopVisible ? "page--top--button" : "page--top--button page--top--button--hidden"} href="#cabinet">Découvrir qui nous sommes</a>
            </div>
            <div className="page--top--gradient"></div>
            <div className="cabinet--content" id="cabinet">
                <div className="cabinet--cabinet">
                    <div className="cabinet--text">
                        <h2>Notre cabinet</h2>
                        <p>DECIMALE est un cabinet d'expertise-comptable et de commissariat aux comptes créé en mai 1993 par Laurent BAUDART, Expert-comptable et Commissaire aux comptes.</p>
                        <p>Nos locaux sont situés au centre-ville de Reims (51), 11, rue des bons malades.</p>
                        <p>Nous accompagnons près de 700 clients dans leurs problématiques comptables, fiscales, financières, sociales, informatiques et juridiques.</p>
                        <p>Le cabinet est composé de 19 collaborateurs sous la direction de Arnaud COHEN (Expert-comptable - Commissaire aux comptes ).</p>
                        <p>Notre cabinet vous accueille du lundi au vendredi de 8h00 à 12h00 et de 14h00 à 18h00 (17h00 le vendredi).</p>
                    </div>
                    <div className="cabinet--pictureContainer">
                        <div className="cabinet--picture" style={{backgroundImage: `url(${reims01})`}}></div>
                    </div>
                </div>
                <div className="cabinet--dirigeants">
                    <h2>Le dirigeant</h2>
                    <div className="dirigeants--container">
                        <div ref={arnaudRef} className={arnaudVisible ? "dirigeant arnaudDiv" : "dirigeant arnaudDiv arnaudDiv--hidden"}>
                            <h3>Arnaud COHEN</h3>
                            <p>Expert-comptable</p>
                            <p>Commissaire aux comptes</p>
                            <div className="dirigeant--picture" id="arnaudPicture" style={{backgroundImage: `url(${arnaudPic})`}}></div>
                            <p className="dirigeant--description">Arnaud COHEN est présent au sein du cabinet depuis 2011, et le dirige depuis 2020.</p>
                            <p className="dirigeant--description">Il est inscrit sur la liste des experts-comptables depuis 2019, date à laquelle il a également prêté serment auprès de la Cour d'appel de Reims pour l'exercice de la profession de Commissaire aux comptes.</p>
                        </div>
                    </div>
                </div>
                <div className="cabinet--histoire">
                    <h2>Notre histoire</h2>
                    <div className="histoire--timeline">
                        <div className="timeline--middleLine"></div>
                        <div className="timeline--section" id="section_1">
                            <div className="histoire--cell" id="cell_A">
                                <div className="histoire--text histoire--text--left">
                                    <h3 className="h--1993">1993</h3>
                                    <p>Création du cabinet par Laurent BAUDART, accompagné de sept collaborateurs.</p>
                                </div>
                            </div>
                            <div className="histoire--cell" id="cell_B">
                                <div className={y1993Visible ? "pic--1993" : "pic--1993 pic--1993--hidden"} ref={y1993Ref}>
                                    <img className="oldLogoPic" src={oldLogo} alt="Decimale's old logo"/>
                                </div>
                            </div>
                        </div>
                        <div className="timeline--section" id="section_2">
                            <div className="histoire--cell histoire--cell--left--pic" id="cell_C">
                                <div className={y2001Visible ? "pic--2001" : "pic--2001 pic--2001--hidden"} ref={y2001Ref} style={{backgroundImage: `url(${euroCoin})`}}></div>
                            </div>
                            <div className="histoire--cell" id="cell_D">
                                <div className="histoire--text histoire--text--right">
                                    <h3 className="h--2001">2001</h3>
                                    <p>Le passage à l'euro, mais également le déménagement du cabinet dans ses locaux actuels situés rue des bons malades à Reims.</p>
                                </div>
                            </div>
                        </div>
                        <div className="timeline--section" id="section_3">
                            <div className="histoire--cell" id="cell_E">
                                <div className="histoire--text histoire--text--left">
                                    <h3 className="h--2023">2023</h3>
                                    <p>Trente ans après son acte de naissance, le cabinet poursuit son développement. Transformation numérique, évolutions informatiques, digitalisation sont les maitres-mots afin d'accompagner nos clients sur les problématiques actuelles et futures.</p>
                                </div>
                            </div>
                            <div className="histoire--cell" id="cell_F">
                                <div className={y2023Visible ? "pic--2023" : "pic--2023 pic--2023--hidden"} ref={y2023Ref} style={{backgroundImage: `url(${digital})`}}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}