import React from "react";
import { Link } from "react-router-dom";
import decimale_fullLogo from "../logos/decimale_fullLogo.jpg";
import PhoneSVG from "../icons/PhoneSVG";
import EmailSVG from "../icons/EmaiSVG";
import ClockSVG from "../icons/ClockSVG";
import LocationSVG from "../icons/LocationSVG";
import linkedinLogo from "../logos/linkedinLogo.png";
import facebookLogo from "../logos/facebookLogo.png";
import crccLogo from "../logos/crcc.png";
import odecLogo from "../logos/odecLogo.png";
import Address from "../helpers/Address";
import y_logo from "../logos/y_logo.png";


export default function Footer(){
    return(
        <footer>
            <div className="topFooter">
                <div className="topFooter--left">
                    <p>DÉCIMALE est membre de</p>
                    <a href="https://www.experts-comptables.fr/" target="_blank" rel="noreferrer">
                        <img src={odecLogo} className="odecLogo" alt="odec logo"></img>
                    </a>
                </div>
                <div className="topFooter--right">
                    <p>DÉCIMALE est membre de</p>
                    <a href="https://www.cncc.fr/" target="_blank" rel="noreferrer">
                        <img src={crccLogo} className="crccLogo" alt="crcc logo"></img>
                    </a>
                </div>
            </div>
            <div className="mainFooter">
                <div className="footer--logoDiv">
                    <img className="footer--logo" src={decimale_fullLogo} alt="decimale logo"/>
                </div>
                <div className="footer--contactDiv">
                    <div className="footer--contactDiv--content">
                        <h2>Contactez-nous</h2>
                        <div className="footer--contactDiv--subDiv">
                            <div className="footerSVGContainer"><PhoneSVG /></div>
                            
                            <p>03 26 05 00 27</p>
                        </div>
                        <a className="footer--contactDiv--subDiv" href="mailto:contact@decimale.fr" >
                            <div className="footerSVGContainer"><EmailSVG /></div>
                            
                            <p>contact@decimale.fr</p>
                        </a>
                        <div className="footer--contactDiv--subDiv">
                            <div className="footerSVGContainer"><LocationSVG /></div>
                            
                            <Address exactAddress="11 Rue des Bons Malades, Reims, France" displayAddress="11 Rue des Bons Malades, 51100 REIMS"/>
                        </div>
                        <div className="footer--contactDiv--subDiv">
                            <div className="footerSVGContainer"><ClockSVG /></div>
                            
                            <div className="contactDiv--subdiv--sub">
                                <p>Du lundi au vendredi de 8h à 12h et de 14h à 18h</p>
                                <p>(17h le vendredi)</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer--navDiv">
                    <ul className="footer--navDiv--list">
                        <li><Link to="/">Accueil</Link></li>
                        <li><Link to="/cabinet">Notre cabinet</Link></li>
                        <li><Link to="/services">Nos services</Link></li>
                        <li><Link to="/secteurs">Secteurs d'activités</Link></li>
                        <li><Link to="/devis">Contact-Devis</Link></li>
                        <li><a href="https://cabinet-decimale.welyb.fr" target="_blank" rel="noopener noreferrer">Espace client</a></li>
                        <li><Link to="/mentionsLegales">Mentions légales</Link></li>
                    </ul>
                    <div className="footer--socialDiv">
                        <a className="footer--socialContainer" href="https://www.linkedin.com/company/sarl-decimale/" target="_blank" rel="noreferrer">
                            <img className="footer--social" src={linkedinLogo} alt="linkedin logo"></img>
                        </a>
                        <a className="footer--socialContainer" href="https://www.facebook.com/cabinet.decimale" target="_blank" rel="noreferrer">
                            <img className="footer--social" src={facebookLogo} alt="facebook logo"></img>
                        </a>
                    </div>
                </div>
            </div>
            <div className="subFooter">
                <p>© DECIMALE 2022 - Tous droits réservés</p>
                <a className="y_websiteLInk" href="https://yoanngrudzien.website/" target="_blank" rel="noreferrer">
                    <p>Site créé par</p>
                    <img className="y_logo" src={y_logo} alt="yoann grudzien's website logo"></img>
                </a>
            </div>
        </footer>
    )
}
