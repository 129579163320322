import React from "react"
import { Link } from "react-router-dom"

export default function ServicesFiscalite(){

    const[subMenuIsActive, setSubMenuIsActive] = React.useState(false);

    function toggleSubMenu(){
        setSubMenuIsActive(previous => !previous);
    }

     // Add a click event listener to the document to handle clicks outside of the menu
    React.useEffect(() => {

        function closeMenuOnClickOutside(event) {
            if (subMenuIsActive && !document.querySelector('.subPage--header--menu').contains(event.target)) {
                setSubMenuIsActive(false);
            }
        }

        document.addEventListener('click', closeMenuOnClickOutside);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('click', closeMenuOnClickOutside);
        };

    }, [subMenuIsActive]);
    
    return(

        <div>
            <div className="subPage--header">
                <h1>Nos Services</h1>
                <div className="subPage--header--menu" onClick={toggleSubMenu}>
                    <p>Nos Services</p>
                    <div className={subMenuIsActive ? "subPage--header--menu--arrow--up" : "subPage--header--menu--arrow--down"}></div>
                    <ul className={subMenuIsActive ? "subPage--header--menu--list--visible" : "subPage--header--menu--list--hidden"}>
                        <li><Link to="/services/expertise">Expertise Comptable</Link></li>
                        <li><Link to="/services/entreprise">Reprise et création d'entreprise</Link></li>
                        <li><Link to="/services/fiscalite">Fiscalité</Link></li>
                        <li><Link to="/services/secretariat">Secrétariat juridique</Link></li>
                        <li><Link to="/services/gestion">Gestion de la paie / assistance sociale</Link></li>
                        <li><Link to="/services/audit">Audit légal et audit contractuel</Link></li>
                        <li><Link to="/services/evaluation">Evaluation</Link></li>
                    </ul>
                </div>
            </div>
            <div className="subPage--content">
                <h2>Fiscalité</h2>
                <p>Le cabinet accompagne, sécurise et oriente les clients dans leurs stratégies financières et fiscales, tout au long de la vie de l’entreprise.</p>
                <p>Au quotidien, cela se matérialise par notre accompagnement pour établir ou contrôler toutes les déclarations fiscales de votre entreprise :</p>
                <ul>
                    <li>Impôts directs</li>
                    <li>TVA et taxes assimilées</li>
                    <li>Fiscalité immobilière</li>
                    <li>Droits d’enregistrement</li>
                    <li>Autres impôts et taxes (CFE, CVAE, TVS, CRL, etc.)</li>
                    <li>Assistance en cas de contrôle opéré par l’Administration fiscale</li>
                </ul>
                <p>Nous disposons également des outils et des compétences afin de conseiller et établir les déclarations fiscales personnelles (IR, IFI, revenus fonciers, etc.) des personnes physiques, dans le cadre de leur stratégie fiscale personnelle.</p>
            </div>
            
        </div>
        
    )

}