import React from "react"
import { Link } from "react-router-dom"

export default function ServicesGestion(){

    const[subMenuIsActive, setSubMenuIsActive] = React.useState(false);

    function toggleSubMenu(){
        setSubMenuIsActive(previous => !previous);
    }

     // Add a click event listener to the document to handle clicks outside of the menu
    React.useEffect(() => {

        function closeMenuOnClickOutside(event) {
            if (subMenuIsActive && !document.querySelector('.subPage--header--menu').contains(event.target)) {
                setSubMenuIsActive(false);
            }
        }

        document.addEventListener('click', closeMenuOnClickOutside);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('click', closeMenuOnClickOutside);
        };

    }, [subMenuIsActive]);
    
    return(

        <div>
            <div className="subPage--header">
                <h1>Nos Services</h1>
                <div className="subPage--header--menu" onClick={toggleSubMenu}>
                    <p>Nos Services</p>
                    <div className={subMenuIsActive ? "subPage--header--menu--arrow--up" : "subPage--header--menu--arrow--down"}></div>
                    <ul className={subMenuIsActive ? "subPage--header--menu--list--visible" : "subPage--header--menu--list--hidden"}>
                        <li><Link to="/services/expertise">Expertise Comptable</Link></li>
                        <li><Link to="/services/entreprise">Reprise et création d'entreprise</Link></li>
                        <li><Link to="/services/fiscalite">Fiscalité</Link></li>
                        <li><Link to="/services/secretariat">Secrétariat juridique</Link></li>
                        <li><Link to="/services/gestion">Gestion de la paie / assistance sociale</Link></li>
                        <li><Link to="/services/audit">Audit légal et audit contractuel</Link></li>
                        <li><Link to="/services/evaluation">Evaluation</Link></li>
                    </ul>
                </div>
            </div>

            <div className="subPage--content">
                <h2>Gestion de la paie et assistance sociale</h2>
                <p>Notre cabinet dispose d’un « pôle social » afin de d’assister ses clients dans tous les aspects
    du droit social et de la gestion de votre personnel.</p>
                <p>Nous pouvons réaliser l’ensemble des tâches suivantes :</p>
                <ul>
                    <li>Etablissement des bulletins de paie</li>
                    <li>Formalisme lié à l’entrée d’un salarié (Déclaration préalable à l’embauche, Etablissement des contrats de travail, etc.)</li>
                    <li>Formalisme lié à la sortie d’un salarié (Soldes de tout compte, attestations annexes, etc.)</li>
                    <li>Suivi des arrêts de travail (maladie, maternité, ...)</li>
                    <li>Etablissement et envoi des déclarations sociales nominatives (DSN) afin de télédéclarer et télé-payer les charges sociales</li>
                </ul>
            </div>
            
        </div>
        
    )

}