import React from "react";
import typing01 from "../pictures/typing01.jpg";
import ClockSVG from "../icons/ClockSVG";
import EmailSVG from "../icons/EmaiSVG";
import LocationSVG from "../icons/LocationSVG";
import PhoneSVG from "../icons/PhoneSVG";
import Address from "../helpers/Address";
import emailjs from '@emailjs/browser';

export default function Devis(){

     // Code to animate the top section of the page on scroll ----------------------------------------
    const [pageTopVisible, setPageTopVisible] = React.useState(false);
    const pageTopRef = React.useRef();
    const flagRefTop = React.useRef(false);

    
    // Code to animate the top section of the page on scroll ----------------------------------------
    const [devisPhoneVisible, setDevisPhoneVisible] = React.useState(false);
    const devisPhoneRef = React.useRef();
    const flagRefDevisPhone = React.useRef(false);
    
    
    // Code to animate the top section of the page on scroll ----------------------------------------
    const [devisEmailVisible, setDevisEmailVisible] = React.useState(false);
    const devisEmailRef = React.useRef();
    const flagRefDevisEmail = React.useRef(false);

    // Code to animate the top section of the page on scroll ----------------------------------------
    const [devisAddressVisible, setDevisAddressVisible] = React.useState(false);
    const devisAddressRef = React.useRef();
    const flagRefDevisAddress = React.useRef(false);
    
    // Code to animate the top section of the page on scroll ----------------------------------------
    const [devisHorairesVisible, setDevisHorairesVisible] = React.useState(false);
    const devisHorairesRef = React.useRef();
    const flagRefDevisHoraires = React.useRef(false);
    

    React.useEffect(() => {

        // for page top
        const pageTopObserver = new IntersectionObserver((entries) => {
            let entry = entries[0];
            if (!flagRefTop.current && entry.isIntersecting) {
                flagRefTop.current = true;
                setPageTopVisible(true);
            }
        }, {
            threshold: 0.5
        })
        const currentPageTop = pageTopRef.current;
        pageTopObserver.observe(currentPageTop);
        
        // for phone
        const devisPhoneObserver = new IntersectionObserver((entries) => {
            let entry = entries[0];
            if (!flagRefDevisPhone.current && entry.isIntersecting) {
                flagRefDevisPhone.current = true;
                setDevisPhoneVisible(true);
            }
        }, {
            threshold: 0.5
        })
        const currentDevisPhone = devisPhoneRef.current;
        devisPhoneObserver.observe(currentDevisPhone);
        
        // for email
        const devisEmailObserver = new IntersectionObserver((entries) => {
            let entry = entries[0];
            if (!flagRefDevisEmail.current && entry.isIntersecting) {
                flagRefDevisEmail.current = true;
                setDevisEmailVisible(true);
            }
        }, {
            threshold: 0.5
        })
        const currentDevisEmail = devisEmailRef.current;
        devisEmailObserver.observe(currentDevisEmail);
        
        // for address
        const devisAddressObserver = new IntersectionObserver((entries) => {
            let entry = entries[0];
            if (!flagRefDevisAddress.current && entry.isIntersecting) {
                flagRefDevisAddress.current = true;
                setDevisAddressVisible(true);
            }
        }, {
            threshold: 0.5
        })
        const currentDevisAddress = devisAddressRef.current;
        devisAddressObserver.observe(currentDevisAddress);
        
        // for horaires
        const devisHorairesObserver = new IntersectionObserver((entries) => {
            let entry = entries[0];
            if (!flagRefDevisHoraires.current && entry.isIntersecting) {
                flagRefDevisHoraires.current = true;
                setDevisHorairesVisible(true);
            }
        }, {
            threshold: 0.5
        })
        const currentDevisHoraires = devisHorairesRef.current;
        devisHorairesObserver.observe(currentDevisHoraires);
       
        return () => {
            pageTopObserver.unobserve(currentPageTop);
            devisPhoneObserver.unobserve(currentDevisPhone);
            devisEmailObserver.unobserve(currentDevisEmail);
            devisAddressObserver.unobserve(currentDevisAddress);
            devisHorairesObserver.unobserve(currentDevisHoraires);
        };

    }, [])

    // -----------------------------------------------------------------------------------------------------------

    // State used to store the contact form's data
    const [formData, setFormData] = React.useState(
        {
            name: "",
            surname: "",
            email: "",
            phone: "",
            city: "",
            company: "",
            message: "",
            accepted: false
        }
    )

    // This function is called everytime the user modifies one of the form's input fields and updates the state which in turn updates the content of the corresponding field.
    function handleChange(event){
        const {name, value, type, checked} = event.target;
        setFormData( previous => { 
            return {
                ...previous,
                [name] : type === "checkbox" ? checked : value
            }}
        )
    }

    function resetForm(){
        setFormData(
            {
                name: "",
                surname: "",
                email: "",
                phone: "",
                city: "",
                company: "",
                message: "",
                accepted: false
            }
        )
    }

    // emailjs service
    const form = React.useRef();
    
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_rrluo32', 'template_dgj1erf', form.current, 'IKoxS2kJF2fp-duud')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    
        resetForm();
        alert("Votre message a bien été envoyé ! Merci d'avoir pris le temps de completer le formulaire.");
    }


    return ( 

        <div className="page">
            <div className="page--top" id="pageTop05" ref={pageTopRef} style={{backgroundImage: `url(${typing01})`}}>
                <div className="page--top--titles">
                    <h1>
                        <span className={pageTopVisible ? "page--top--mainTitle" : "page--top--mainTitle page--top--mainTitle--hidden"}>CONTACT-DEVIS</span>
                        <br/>
                        <span className={pageTopVisible ? "page--top--subtitle" : "page--top--subtitle page--top--subtitle--hidden"}>Quelque-soit votre question</span>
                        <br/>
                        <span className={pageTopVisible ? "page--top--subtitle" : "page--top--subtitle page--top--subtitle--hidden"}>notre équipe se tient à votre disposition</span>
                    </h1>
                </div>
                <a className={pageTopVisible ? "page--top--button" : "page--top--button page--top--button--hidden"} href="#contact">Nous contacter</a>
            </div>
            <div className="page--top--gradient"></div>
            <div className="devis--content" id="contact">
                <div className="devis--infoSection">
                    <h2>Échangeons autour de vos besoins</h2>
                    <div className="infoSection--row">
                        <div className={devisPhoneVisible ? "infoSection--box" : "infoSection--box infoSection--box--hidden01"} ref={devisPhoneRef}>
                            <div className="infoSection--box--top">Téléphone<PhoneSVG/></div>
                            <p>03 26 05 00 27</p>
                        </div>
                        <div className={devisEmailVisible ? "infoSection--box" : "infoSection--box infoSection--box--hidden02"} ref={devisEmailRef}>
                            <a href="mailto:contact@decimale.fr" className="infoSection--box--top">Email<EmailSVG/></a>
                            <p><a href="mailto:contact@decimale.fr">contact@decimale.fr</a></p>
                        </div>
                    </div>
                    <div className="infoSection--row">
                        <div className={devisAddressVisible ? "infoSection--box" : "infoSection--box infoSection--box--hidden01"} ref={devisAddressRef}>
                            <div className="infoSection--box--top">Adresse<LocationSVG/></div>
                            <Address exactAddress="11 Rue des Bons Malades, Reims, France" displayAddress="11 Rue des Bons Malades, 51100 REIMS"/>
                        </div>
                        <div className={devisHorairesVisible ? "infoSection--box" : "infoSection--box infoSection--box--hidden02"} ref={devisHorairesRef}>
                            <div className="infoSection--box--top">Horaires<ClockSVG/></div>
                            <p>Du lundi au vendredi de 8h à 12h et de 14h à 18h (17h le vendredi)</p>
                        </div>
                    </div>
                </div>
                <form className="contactForm" ref={form} onSubmit={sendEmail}>
                    <h2>Nous contacter</h2>
                    <div className="formColumns">
                        <div className="formCol">
                            <label>
                                Prénom
                                <input 
                                className="formInputText"
                                type="text" 
                                value={formData.name} 
                                onChange={handleChange}
                                name="name"
                                required
                                />
                            </label>

                            <label>
                                Nom de famille
                                <input 
                                className="formInputText"
                                type="text" 
                                value={formData.surname} 
                                onChange={handleChange}
                                name="surname"
                                required
                                />
                            </label>

                            <label>
                                Adresse email
                                <input
                                className="formInputText"
                                type="email" 
                                value={formData.email} 
                                onChange={handleChange} 
                                name="email"
                                required
                                />
                            </label>
                        </div>
                        <div className="formCol">
                            <label>
                                Téléphone
                                <input 
                                className="formInputText"
                                type="text" 
                                value={formData.phone} 
                                onChange={handleChange}
                                name="phone"
                                required
                                />
                            </label>

                            <label>
                                Ville
                                <input 
                                className="formInputText"
                                type="text" 
                                value={formData.city} 
                                onChange={handleChange}
                                name="city"
                                />
                            </label>

                            <label>
                                Société
                                <input 
                                className="formInputText"
                                type="text" 
                                value={formData.company} 
                                onChange={handleChange}
                                name="company"
                                />
                            </label>
                        </div>
                    </div>
                    <label>
                        Message
                        <textarea
                        className="formInputTextArea"
                        value={formData.message} 
                        onChange={handleChange} 
                        name="message"
                        required
                        />
                    </label>

                    <label className="formCheckboxLabel">
                        <input
                            className="formCheckbox"
                            type="checkbox"
                            checked={formData.accepted}
                            onChange={handleChange} 
                            name="accepted"
                            required
                        />
                    En soumettant ce formulaire, j'accepte que les informations saisies soient exploitées dans le cadre de la demande de devis et de la relation commerciale qui peut en découler.
                    </label>

                    <button className="formButton" type="submit">Envoyer</button>

                </form>
            </div>
        </div>
    )
}