import React from 'react';

const AnimatedNumber = (props) => {

    const targetValue = props.targetValue;
    const duration = props.duration;

    const [currentValue, setCurrentValue] = React.useState(0);

    React.useEffect(() => {
        const increment = Math.ceil(targetValue / (duration * 1000 / 60)); // calculate how much to increment the value at each interval
        let timer = setInterval(() => {
        setCurrentValue((prevValue) => {
            if (prevValue + increment >= targetValue) {
            clearInterval(timer); // stop the timer if the current value reaches the target value
            return targetValue;
            }
            return prevValue + increment;
        });
        }, (duration * 1000 / 60)); // 60 fps

        return () => {
        clearInterval(timer); // clean up the timer when the component unmounts
        };
    }, [duration, targetValue, currentValue]);

    return <span className='figureSpan'>{currentValue}</span>;
};

export default AnimatedNumber;