import React from "react";
import office_meeting02 from "../pictures/office_meeting02.jpg";
import { Link } from "react-router-dom";

import BagSVG from "../icons/BagSVG";
import FactorySVG from "../icons/FactorySVG";
import FurnitureSVG from "../icons/FurnitureSVG";
import MedicalSVG from "../icons/MedicalSVG";
import RealEstateSVG from "../icons/RealEstateSVG";
import SellSVG from "../icons/SellSVG";
import StoreSVG from "../icons/StoreSVG";
import Tools02SVG from "../icons/Tools02SVG";
import StartupSVG from "../icons/StartupSVG";
import ServicesSVG from "../icons/ServicesSVG";
import NetworkSVG from "../icons/NetworkSVG";
import PersonSVG from "../icons/PersonSVG";



export default function Secteurs(){

    // Code to use animations on scrolls for the top section of the page ----------------------------------------
    const [pageTopVisible, setPageTopVisible] = React.useState(false);
    const pageTopRef = React.useRef();
    // flagRefTop's purpose is to ensure that the state pageTopvisible will only be set to true once and never change
    const flagRefTop = React.useRef(false);

    React.useEffect(() => {

        const pageTopObserver = new IntersectionObserver((entries) => {
            let entry = entries[0];
            // by setting flagRefTop to true here, we are making sure that even if the component rerenders, pageTopVisible will remain true since a ref will persist in case of renrendering
            if (!flagRefTop.current && entry.isIntersecting) {
                flagRefTop.current = true;
                setPageTopVisible(true);
            }
        }, {
            threshold: 0.5
        })

        const currentPageTop = pageTopRef.current;
        pageTopObserver.observe(currentPageTop);

        return () => {
            pageTopObserver.unobserve(currentPageTop);
        };

    }, [])

    // -----------------------------------------------------------------------------------------------------------

    return ( 
        <div className="page">
            <div className="page--top" id="pageTop04" ref={pageTopRef} style={{backgroundImage: `url(${office_meeting02})`}}>
                <div className="page--top--titles">
                    <h1>
                        <span className={pageTopVisible ? "page--top--mainTitle" : "page--top--mainTitle page--top--mainTitle--hidden"}>SECTEURS D'ACTIVITÉS</span>
                        <br/>
                        <span className={pageTopVisible ? "page--top--subtitle" : "page--top--subtitle page--top--subtitle--hidden"}>Quelque soit votre métier</span>
                        <br/>
                        <span className={pageTopVisible ? "page--top--subtitle" : "page--top--subtitle page--top--subtitle--hidden"}>nous accompagnons vos projets</span>
                    </h1>
                </div>
                <a className={pageTopVisible ? "page--top--button" : "page--top--button page--top--button--hidden"} href="#secteurs">Votre secteur</a>
            </div>
            <div className="page--top--gradient"></div>
            <div className="secteurs--content" id="secteurs">
                <h2>Votre secteur</h2>
                <div className="secteurs--grid">

                    <div className="secteurs--grid--row">

                        <Link to="/secteurs/commerce" >
                            <div className="secteurCard">
                                <p>Commerce</p>
                                <div className="secteurCard--round">
                                    <StoreSVG />
                                </div>
                                <button className="service--button"><div className="service--button--yellowBar"></div>Découvrir</button>
                            </div>
                        </Link>

                        <Link to="/secteurs/artisanat">
                            <div className="secteurCard">
                                <p>Artisanat</p>
                                <div className="secteurCard--round">
                                    <Tools02SVG />
                                </div>
                                <button className="service--button"><div className="service--button--yellowBar"></div>Découvrir</button>
                            </div>
                        </Link>
                        
                        <Link to="/secteurs/industrie">
                            <div className="secteurCard">
                                <p>Industrie</p>
                                <div className="secteurCard--round">
                                    <FactorySVG />
                                </div>
                                <button className="service--button"><div className="service--button--yellowBar"></div>Découvrir</button>
                            </div>
                        </Link>
                        
                        <Link to="/secteurs/medical">
                            <div className="secteurCard">
                                <p>Médical</p>
                                <div className="secteurCard--round">
                                    <MedicalSVG />
                                </div>
                                <button className="service--button"><div className="service--button--yellowBar"></div>Découvrir</button>
                            </div>
                        </Link>
                        
                    </div>
                    <div className="secteurs--grid--row">

                        <Link to="/secteurs/liberales">
                            <div className="secteurCard">
                                <p>Professions libérales</p>
                                <div className="secteurCard--round">
                                    <BagSVG />
                                </div>
                                <button className="service--button"><div className="service--button--yellowBar"></div>Découvrir</button>
                            </div>
                        </Link>
                        
                        <Link to="/secteurs/immobilier">
                            <div className="secteurCard">
                                <p>Immobilier</p>
                                <div className="secteurCard--round">
                                    <RealEstateSVG />
                                </div>
                                <button className="service--button"><div className="service--button--yellowBar"></div>Découvrir</button>
                            </div>
                        </Link>
                        
                        <Link to="/secteurs/marchands">
                            <div className="secteurCard">
                                <p>Marchands de biens</p>
                                <div className="secteurCard--round">
                                    <SellSVG />
                                </div>
                                <button className="service--button"><div className="service--button--yellowBar"></div>Découvrir</button>
                            </div>
                        </Link>
                        
                        <Link to="/secteurs/locations">
                            <div className="secteurCard">
                                <p>Locations meublées</p>
                                <div className="secteurCard--round">
                                    <FurnitureSVG />
                                </div>
                                <button className="service--button"><div className="service--button--yellowBar"></div>Découvrir</button>
                            </div>
                        </Link>

                    </div>
                    <div className="secteurs--grid--row">

                        <Link to="/secteurs/startup">
                            <div className="secteurCard">
                                <p>Startup</p>
                                <div className="secteurCard--round">
                                    <StartupSVG />
                                </div>
                                <button className="service--button"><div className="service--button--yellowBar"></div>Découvrir</button>
                            </div>
                        </Link>
                        
                        <Link to="/secteurs/holding">
                            <div className="secteurCard">
                                <p>Holding</p>
                                <div className="secteurCard--round">
                                    <NetworkSVG />
                                </div>
                                <button className="service--button"><div className="service--button--yellowBar"></div>Découvrir</button>
                            </div>
                        </Link>
                        
                        <Link to="/secteurs/association">
                            <div className="secteurCard">
                                <p>Association</p>
                                <div className="secteurCard--round">
                                    <PersonSVG />
                                </div>
                                <button className="service--button"><div className="service--button--yellowBar"></div>Découvrir</button>
                            </div>
                        </Link>
                        
                        <Link to="/secteurs/consultants">
                            <div className="secteurCard">
                                <p>Consultants</p>
                                <div className="secteurCard--round">
                                    <ServicesSVG />
                                </div>
                                <button className="service--button"><div className="service--button--yellowBar"></div>Découvrir</button>
                            </div>
                        </Link>

                    </div>

                </div>
            </div>
        </div>
    )
}