import React from "react";
import { Link } from "react-router-dom";
import dLogo from "../logos/decimale_dLogo.jpg";
import titleLogo from "../logos/decimale_titleLogo.jpg";
import linkedinLogo from "../logos/linkedinLogo.png";
import facebookLogo from "../logos/facebookLogo.png";

export default function Navbar(){

    // code to set the position of the mobile menu ("nav--links" element) with the mobile menu button -----
    const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);

    function toggleMenu(){
        setMobileMenuOpen(previous => !previous);
    }

    function closeMobileMenu(){
        setMobileMenuOpen(false);
    }

    // code to observe the upperNav and make the mainNav smaller when scrolling down --------------
    const upperNavRef = React.useRef();
    const [upperNavVisible, setVisible] = React.useState(false); 

    React.useEffect(() => {

        const myObserver = new IntersectionObserver((entries) => {
            const entry = entries[0];
            setVisible(entry.isIntersecting);
        }, {
            threshold: 0
        })
        myObserver.observe(upperNavRef.current);

    }, []);

    // --------------------------------------------------------------------------------------------

    return(
        <nav className="nav">
            <div className={upperNavVisible ? "upperNav" : "upperNav upperNavHidden"} ref={upperNavRef}>
                <a href="https://www.linkedin.com/company/sarl-decimale/" target="_blank" rel="noreferrer">
                    <img className="upperNav--social" src={linkedinLogo} alt="linkedin logo"></img>
                </a>
                <a href="https://www.facebook.com/cabinet.decimale" target="_blank" rel="noreferrer">
                    <img className="upperNav--social" src={facebookLogo} alt="facebook logo"></img>
                </a>
            </div>
            <div className={upperNavVisible ? "mainNav" : "mainNav mainNavFixed"}>
                <Link to="/" onClick={closeMobileMenu}>
                    <div className="nav--logos">
                        <div className="nav--logo01"><img src={dLogo} alt="decimale logo"></img></div>
                        <div className="nav--logo02"><img src={titleLogo} alt="decimale logo"></img></div>
                    </div>
                </Link>
                <ul className={mobileMenuOpen ? "nav--links nav--links--Open" : "nav--links"}>
                    <li><Link to="/cabinet" onClick={closeMobileMenu}>Notre cabinet</Link></li>
                    <li><Link to="/services" onClick={closeMobileMenu}>Nos services</Link></li>
                    <li><Link to="/secteurs" onClick={closeMobileMenu}>Secteurs d'activités</Link></li>
                    <li><Link to="/devis" onClick={closeMobileMenu}>Contact-Devis</Link></li>
                    <li><a href="https://cabinet-decimale.welyb.fr" onClick={closeMobileMenu} target="_blank" rel="noopener noreferrer">Espace client</a></li>
                </ul>
                <div className="nav--menuButtonContainer">
                    <button className={mobileMenuOpen ? "nav--menuButton nav--menuButton--cross" : "nav--menuButton"} onClick={toggleMenu}>
                        <div className={mobileMenuOpen ? "menuButton--topBar menuButton--topBar--cross" : "menuButton--topBar"}></div>
                        <div className={mobileMenuOpen ? "menuButton--middleBar menuButton--middleBar--cross" : "menuButton--middleBar"}></div>
                        <div className={mobileMenuOpen ? "menuButton--bottomBar menuButton--bottomBar--cross" : "menuButton--bottomBar"}></div>
                    </button>
                </div>
            </div>
        </nav>
    )
}