import React from "react"
import { Link } from "react-router-dom";

export default function ServicesEvaluation(){

    const[subMenuIsActive, setSubMenuIsActive] = React.useState(false);

    function toggleSubMenu(){
        setSubMenuIsActive(previous => !previous);
    }

     // Add a click event listener to the document to handle clicks outside of the menu
    React.useEffect(() => {

        function closeMenuOnClickOutside(event) {
            if (subMenuIsActive && !document.querySelector('.subPage--header--menu').contains(event.target)) {
                setSubMenuIsActive(false);
            }
        }

        document.addEventListener('click', closeMenuOnClickOutside);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('click', closeMenuOnClickOutside);
        };

    }, [subMenuIsActive]);
    
    return(

        <div>
            <div className="subPage--header">
                <h1>Nos Services</h1>
                <div className="subPage--header--menu" onClick={toggleSubMenu}>
                    <p>Nos Services</p>
                    <div className={subMenuIsActive ? "subPage--header--menu--arrow--up" : "subPage--header--menu--arrow--down"}></div>
                    <ul className={subMenuIsActive ? "subPage--header--menu--list--visible" : "subPage--header--menu--list--hidden"}>
                        <li><Link to="/services/expertise">Expertise Comptable</Link></li>
                        <li><Link to="/services/entreprise">Reprise et création d'entreprise</Link></li>
                        <li><Link to="/services/fiscalite">Fiscalité</Link></li>
                        <li><Link to="/services/secretariat">Secrétariat juridique</Link></li>
                        <li><Link to="/services/gestion">Gestion de la paie / assistance sociale</Link></li>
                        <li><Link to="/services/audit">Audit légal et audit contractuel</Link></li>
                        <li><Link to="/services/evaluation">Evaluation</Link></li>
                    </ul>
                </div>
            </div>
            
            <div className="subPage--content">
                <h2>Evaluation</h2>
                <p>Votre entreprise peut être amenée au cours de sa vie à procéder à une évaluation dans le cadre d’opérations de cession et d’acquisition, mais aussi dans des contextes successoraux, financiers, contentieux, etc. L’évaluation peut également s’avérer importante comme outil de gestion de votre entreprise. Dans tous les cas, cette opération complexe nécessite des compétences particulières.</p>
                <p>Notre cabinet vous accompagne dans le cadre de mission d’évaluations d’entreprise en fonction du contexte de cette demande (intégration de nouveaux associés, d’une cession, d’une transmission, d’une acquisition, etc.).</p>
            </div>
            
        </div>
        
    )

}