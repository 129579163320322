import React from "react";
import { useLocation } from "react-router-dom";

// ScrollToTop is a helper that allows the scroll position to be reset to the top of the page when navigating with React Router.
// By default, for example, navigating to a new page while in the middle of another one would take you to the middle of the new page (kind of).

export default function ScrollToTop() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, [pathname]);

  return null;
}