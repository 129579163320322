import React from "react";
import { Link } from "react-router-dom";
import accounting02 from "../pictures/accounting02.jpg";
import PeopleSVG from "../icons/PeopleSVG";
import EyeSVG from "../icons/EyeSVG";
import FingerprintSVG from "../icons/FingerprintSVG";
import MagicSVG from "../icons/MagicSVG";
import AnimatedNumber from "../helpers/AnimateNumber";


export default function Home(){

    // Animations on scrolls for the top section of the page ---------------------------------------------
    const [pageTopVisible, setPageTopVisible] = React.useState(false);
    const pageTopRef = React.useRef();
    const flagRefTop = React.useRef(false);

    // Animate the let's meet section on scroll
    const [letsMeetVisible, setLetsMeetVisible] = React.useState(false);
    const letsMeetRef = React.useRef();
    const flagRefLetsMeet = React.useRef(false);

    // Animations on scrolls for the figures ---------------------------------------------------
    const [figuresVisible, setFiguresVisible] = React.useState(false);
    const figuresRef = React.useRef();
    const figuresFlagRef = React.useRef(false);

    React.useEffect(() => {

        // for the page top
        const pageTopObserver = new IntersectionObserver((entries) => {
            let entry = entries[0];
            if (!flagRefTop.current && entry.isIntersecting) {
                flagRefTop.current = true;
                setPageTopVisible(true);
            }
        }, {
            threshold: 0.5
        })
        const currentPageTop = pageTopRef.current;
        pageTopObserver.observe(currentPageTop);

        // for the let's meet section
        const letsMeetObserver = new IntersectionObserver((entries) => {
            let entry = entries[0];
            if (!flagRefLetsMeet.current && entry.isIntersecting) {
                flagRefLetsMeet.current = true;
                setLetsMeetVisible(true);
            }
        }, {
            threshold: 0.5
        })
        const currentLetsMeet = letsMeetRef.current;
        letsMeetObserver.observe(currentLetsMeet);

        // for the figures
        const figuresObserver = new IntersectionObserver((entries) => {
            let entry = entries[0];
            if (!figuresFlagRef.current && entry.isIntersecting) {
                figuresFlagRef.current = true;
                setFiguresVisible(true);
            }
        }, {
            threshold: 0.5
        })
        const currentFigures = figuresRef.current;
        figuresObserver.observe(currentFigures);

        return () => {
            pageTopObserver.unobserve(currentPageTop);
            letsMeetObserver.unobserve(currentLetsMeet);
            figuresObserver.unobserve(currentFigures);
        };

    }, [])

    // -----------------------------------------------------------------------------------------------------------

    return ( 
        <div className="page">
            <div className="page--top" id="pageTop01" ref={pageTopRef} style={{backgroundImage: `url(${accounting02})`}}>
                <div className="page--top--titles">
                    <h1>
                        <span className={pageTopVisible ? "page--top--mainTitle" : "page--top--mainTitle page--top--mainTitle--hidden"}>DÉCIMALE</span>
                        <br/>
                        <span className={pageTopVisible ? "page--top--subtitle" : "page--top--subtitle page--top--subtitle--hidden"}>Expertise comptable</span>
                        <br/>
                        <span className={pageTopVisible ? "page--top--subtitle" : "page--top--subtitle page--top--subtitle--hidden"}>Commissariat aux comptes</span>
                        <br/>
                        <span className={pageTopVisible ? "page--top--subtitle" : "page--top--subtitle page--top--subtitle--hidden"}>Conseil</span>
                    </h1>
                </div>
                <a className={pageTopVisible ? "page--top--button" : "page--top--button page--top--button--hidden"} href="#offre">Découvrir notre offre</a>
            </div>
            <div className="page--top--gradient"></div>
            <div className="home--content" id="offre">
                <div className="home--figures">
                    <h2>Une équipe de professionels au service de votre entreprise</h2>
                    <p className="home--figures--subHeader">Décimale en quelques chiffres clés :</p>
                    <div className="figures--row">
                        <div className="figures--box" ref={figuresRef}>
                            <p className="figures--mumber">
                                {figuresVisible ? <AnimatedNumber targetValue={30} duration={2.5}/> : 0}
                            </p>
                            <p className="figures--text">ans d'experience.</p>
                        </div>
                        <div className="figures--box">
                            <p className="figures--mumber">
                                {figuresVisible ? <AnimatedNumber targetValue={18} duration={4.5}/> : 0}
                            </p>
                            <p className="figures--text">collaborateurs</p>
                        </div>
                        <div className="figures--box">
                            <p className="figures--mumber">
                                {figuresVisible ? <AnimatedNumber targetValue={700} duration={2}/> : 0}
                            </p>
                            <p className="figures--text">clients accompagnés</p>
                        </div>
                    </div>
                    <Link to="cabinet"><button className="figures--button">Découvrir notre cabinet</button></Link>
                </div>
                <div className="home--whyChooseUs">
                    <h2>Pourquoi choisir Décimale ?</h2>
                    <div className="WhyChooseUs--row">
                        <div className="whyChooseUs--subrow1">
                            <div className="whyChooseUs--box">
                                <div className="wcu--icon">
                                    <PeopleSVG />
                                </div>
                                <h3>Une histoire</h3>
                                <p>Nous sommes fiers de nos 30 ans d'expérience en tant que cabinet comptable de confiance pour les entreprises locales.</p>
                            </div>
                            <div className="whyChooseUs--box">
                                <div className="wcu--icon">
                                    <EyeSVG />
                                </div>
                                <h3>Notre vision</h3>
                                <p>Aider nos clients à atteindre leurs objectifs financiers en leur fournissant une expertise de haute qualité avec un accent sur l'optimisation.</p>
                            </div>
                        </div>
                        <div className="whyChooseUs--subrow2">
                            <div className="whyChooseUs--box">
                                <div className="wcu--icon">
                                    <FingerprintSVG />
                                </div>
                                <h3>Notre philosophie</h3>
                                <p>Simplifier la comptabilité pour nos clients, tout en leur fournissant une expertise et un soutien humain et personnalisé.</p>
                            </div>
                            <div className="whyChooseUs--box">
                                <div className="wcu--icon">
                                    <MagicSVG />
                                </div>
                                <h3>Nos +</h3>
                                <p>Un accompagnement personnalisé, ainsi que des technologies modernes pour une gestion efficace de la comptabilité.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="home--letsMeet" ref={letsMeetRef}>
                    <p className={letsMeetVisible ? "letsMeetText" : "letsMeetText letsMeetText--hidden"}>Nos experts se tiennent à votre disposition pour vous accompagner dans vos projets et répondre à vos questions.</p>
                    <Link to="/devis"><button className={letsMeetVisible ? "letsMeet--button" : "letsMeet--button letsMeet--button--hidden"}>Rencontrons nous</button></Link>
                </div>
                {/* <div className="home--testimonials">
                    <h2>Ils ont fait le bon choix !</h2>
                    <p>Nos clients témoignent</p>
                    <div className="testimonials--container">
                        <p className="testimonial">"J'ai travaillé avec Décimale pour la première fois cette année et je suis vraiment impressionné par leur niveau d'expertise et leur engagement. Le personnel est très professionnel, toujours disposé à répondre à mes questions et leur accompagnement personnalisé a été inestimable pour m'aider à atteindre mes objectifs financiers. Je recommande vivement ce cabinet à toute entreprise en quête d'un partenaire comptable fiable et efficace."</p>
                        <p className="testimonialAuthor">Didier G.</p>
                    </div>
                </div> */}
            </div>
        </div>
    )
}
