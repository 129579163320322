export default function LegalMentions(){

    return(

        <div>
            <div className="legalMentions--header">
                <h1>Mentions Légales</h1>
            </div>

            <div className="legalMentions--content">
                
                <div className="legalMentions--content--section">
                    <h2>Propriétaire du site / Réalisation & Conception</h2>
                    <p>SARL DECIMALE</p>
                    <p>11, rue des bons malades</p>
                    <p>51100 REIMS</p>
                    <p>Tél : 03 26 05 00 27</p>
                    <p>Mail : contact@decimale.fr</p>
                    <p>SARL au capital de 100 000 €</p>
                    <p>S.I.R.E.T. 391 495 298 00039</p>
                    <p>RCS Reims B 391 495 298</p>
                </div>
                <div className="legalMentions--content--section">
                    <h2>Directeur de la publication</h2>
                    <p>Le directeur de la publication est :</p>
                    <p>Monsieur Arnaud Cohen</p>
                    <p>arnaud.cohen@decimale.fr</p>
                </div>
                <div className="legalMentions--content--section">
                    <h2>Hébergement</h2>
                    <p>La Gestion, la maintenance et le suivi de l'hébergement est réalisé en interne.</p>
                </div>
                <div className="legalMentions--content--section">
                    <h2>Propriété Intellectuelle</h2>
                    <p>Tout le contenu de ce site web, y compris mais sans s'y limiter, les textes, les images, les logos et les graphiques, est la propriété intellectuelle de SARL DECIMALE et est protégé par les lois sur le droit d'auteur. Aucune reproduction, distribution ou utilisation non autorisée n'est permise sans le consentement écrit préalable de SARL DECIMALE.</p>
                </div>
                <div className="legalMentions--content--section">
                    <h2>Utilisation du Site Web</h2>
                    <p>En utilisant ce site web, vous acceptez les présentes conditions d'utilisation. Vous vous engagez à ne pas utiliser ce site web à des fins illicites ou interdites par la loi.</p>
                </div>
                <div className="legalMentions--content--section">
                    <h2>Modification des Mentions Légales</h2>
                    <p>SARL DECIMALE se réserve le droit de modifier les présentes mentions légales à tout moment. Les modifications prendront effet dès leur publication sur ce site web.</p>
                </div>
                <div className="legalMentions--content--section">
                    <h2>Loi Applicable et Juridiction</h2>
                    <p>Les présentes mentions légales sont régies par le droit français. Tout litige découlant de l'utilisation de ce site web sera soumis à la juridiction exclusive des tribunaux français.</p>
                </div>
                
            </div>
        </div>

    )

}