import React from "react";
import { Link } from "react-router-dom";
import office_meeting01 from "../pictures/office_meeting01.jpg";
import CalculateSVG from "../icons/CalculateSVG";
import AddBusinessSVG from "../icons/AddBusinessSVG";
import AdministrationSVG from "../icons/AdministrationSVG";
import GavelSVG from "../icons/GavelSVG";
import MoneySVG from "../icons/MoneySVG";
import ContractSVG from "../icons/ContractSVG";
import EvaluateSVG from "../icons/EvaluateSVG";

export default function Services(){

    // Code to animate elements on scroll ----------------------------------------
    const [pageTopVisible, setPageTopVisible] = React.useState(false);
    const pageTopRef = React.useRef();
    // flagRefTop's purpose is to ensure that the state pageTopvisible will only be set to true once and never change
    const flagRefTop = React.useRef(false);

    React.useEffect(() => {

        const pageTopObserver = new IntersectionObserver((entries) => {
            let entry = entries[0];
            // by setting flagRefTop to true here, we are making sure that even if the component rerenders, pageTopVisible will remain true since a ref will persist in case of renrendering
            if (!flagRefTop.current && entry.isIntersecting) {
                flagRefTop.current = true;
                setPageTopVisible(true);
            }
        }, {
            threshold: 0.5
        })

        const currentPageTop = pageTopRef.current;
        pageTopObserver.observe(currentPageTop);

        return () => {
            pageTopObserver.unobserve(currentPageTop);
        };

    }, [])

    // --------------------------------------------------------------------------------------------------------

    return (
        <div className="page">
            <div className="page--top" id="pageTop03" ref={pageTopRef} style={{backgroundImage: `url(${office_meeting01})`}}>
                <div className="page--top--titles">
                    <h1>
                        <span className={pageTopVisible ? "page--top--mainTitle" : "page--top--mainTitle page--top--mainTitle--hidden"}>NOS SERVICES</span>
                        <br/>
                        <span className={pageTopVisible ? "page--top--subtitle" : "page--top--subtitle page--top--subtitle--hidden"}>Des solutions pour</span>
                        <br/>
                        <span className={pageTopVisible ? "page--top--subtitle" : "page--top--subtitle page--top--subtitle--hidden"}>toutes les entreprises</span>
                    </h1>
                </div>
                <a className={pageTopVisible ? "page--top--button" : "page--top--button page--top--button--hidden"} href="#services">Découvrir nos offres</a>
            </div>
            <div className="page--top--gradient"></div>

            <div className="services--content" id="services">

                <div className="services--grid">

                    <Link to="/services/expertise" className="serviceBoxContainer">
                        <div className="serviceBox">
                            <p className="service--title">EXPERTISE COMPTABLE</p>
                            <div className="service--round">
                                <CalculateSVG />
                            </div>
                            <button className="service--button"><div className="service--button--yellowBar"></div>Découvrir</button>
                        </div>
                    </Link>

                    <Link to="/services/entreprise" className="serviceBoxContainer">
                        <div className="serviceBox">
                            <p className="service--title">CONSEILS ET ACCOMPAGNEMENT A LA CREATION ET REPRISE D’ENTREPRISE</p>    
                            <div className="service--round">
                                <AddBusinessSVG />
                            </div>
                            <button className="service--button"><div className="service--button--yellowBar"></div>Découvrir</button>
                        </div>
                    </Link>

                    <Link to="/services/fiscalite" className="serviceBoxContainer">
                        <div className="serviceBox">
                            <p className="service--title">FISCALITÉ</p>    
                            <div className="service--round">
                                <AdministrationSVG />
                            </div>
                            <button className="service--button"><div className="service--button--yellowBar"></div>Découvrir</button>
                        </div>
                    </Link>

                    <Link to="/services/secretariat" className="serviceBoxContainer">
                        <div className="serviceBox">
                            <p className="service--title">SECRETARIAT JURIDIQUE</p>    
                            <div className="service--round">
                                <GavelSVG />
                            </div>
                            <button className="service--button"><div className="service--button--yellowBar"></div>Découvrir</button>
                        </div>
                    </Link>

                    <Link to="/services/gestion" className="serviceBoxContainer">
                        <div className="serviceBox">
                            <p className="service--title">GESTION DE LA PAIE / ASSISTANCE SOCIALE</p>
                            <div className="service--round">
                                <MoneySVG />
                            </div>
                            <button className="service--button"><div className="service--button--yellowBar"></div>Découvrir</button>
                        </div>
                    </Link>

                    <Link to="/services/audit" className="serviceBoxContainer">
                        <div className="serviceBox">
                            <p className="service--title">AUDIT LEGAL ET AUDIT CONTRACTUEL</p>
                            <div className="service--round">
                                <ContractSVG />
                            </div>
                            <button className="service--button"><div className="service--button--yellowBar"></div>Découvrir</button>
                        </div>
                    </Link>

                    <Link to="/services/evaluation" className="serviceBoxContainer">
                        <div className="serviceBox">
                            <p className="service--title">EVALUATION</p>    
                            <div className="service--round">
                                <EvaluateSVG />
                            </div>
                            <button className="service--button"><div className="service--button--yellowBar"></div>Découvrir</button>
                        </div>
                    </Link> 
                </div>
            </div>
        </div>
    )
}