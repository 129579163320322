import React from "react"
import { Link } from "react-router-dom"

export default function SecteursAssociation(){
    
    const[subMenuIsActive, setSubMenuIsActive] = React.useState(false);

    function toggleSubMenu(){
        setSubMenuIsActive(previous => !previous);
    }

     // Add a click event listener to the document to handle clicks outside of the menu
    React.useEffect(() => {

        function closeMenuOnClickOutside(event) {
            if (subMenuIsActive && !document.querySelector('.subPage--header--menu').contains(event.target)) {
                setSubMenuIsActive(false);
            }
        }

        document.addEventListener('click', closeMenuOnClickOutside);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('click', closeMenuOnClickOutside);
        };

    }, [subMenuIsActive]);

    return(

        <div>
            <div className="subPage--header">
                <h1>Secteurs</h1>
                <div className="subPage--header--menu" onClick={toggleSubMenu}>
                    <p>Les Secteurs</p>
                    <div className={subMenuIsActive ? "subPage--header--menu--arrow--up" : "subPage--header--menu--arrow--down"}></div>
                    <ul className={subMenuIsActive ? "subPage--header--menu--list--visible" : "subPage--header--menu--list--hidden"}>
                        <li><Link to='/secteurs/artisanat'>Artisanat</Link></li>
                        <li><Link to='/secteurs/association'>Association</Link></li>
                        <li><Link to='/secteurs/commerce'>Commerce</Link></li>
                        <li><Link to='/secteurs/consultants'>Consultants</Link></li>
                        <li><Link to='/secteurs/holding'>Holding</Link></li>
                        <li><Link to='/secteurs/immobilier'>Immobilier</Link></li>
                        <li><Link to='/secteurs/industrie'>Industrie</Link></li>
                        <li><Link to='/secteurs/liberales'>Professions liberales</Link></li>
                        <li><Link to='/secteurs/locations'>Locations meublées</Link></li>
                        <li><Link to='/secteurs/marchands'>Marchands de biens</Link></li>
                        <li><Link to='/secteurs/medical'>Médical</Link></li>
                        <li><Link to='/secteurs/startup'>Startup</Link></li>
                    </ul>
                </div>
            </div>
            
            <div className="subPage--content">
                <h2>Association</h2>
                <p>Dans une volonté de transparence, et afin de donner une meilleure visibilité de la situation financière de leurs structures, de nombreuses associations ont recours au service d’un cabinet d’expertise-comptable.</p>
                <p>Notre cabinet accompagne les associations durant les différents cycles de vie d’une association.</p>
                <p>Notre assistance peut consister notamment à :</p>
                <ul>
                    <li>Création de la structure</li>
                    <li>Gestion comptable : conformité de la comptabilité, suivi de la trésorerie</li>
                    <li>Suivi de la réglementation juridique et fiscale en matière d’assujettissement aux impôts commerciaux et aux règles spécifiques du Mécénat</li>
                    <li>Etablir les bulletins de salaire et les déclarations sociales (DSN, prélèvement à la source, taxe sur les salaires, etc.) et conseiller les clients sur leurs choix en matière d’organisation</li>
                    <li>Audit légal et contractuel</li>
                </ul>
            </div>
            
        </div>
        
    )

}