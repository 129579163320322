import React from "react";

function Address (props) {

    const exactAddress = props.exactAddress;
    const displayAddress = props.displayAddress;

    const handleAddressClick = () => {
      const addressUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(exactAddress)}`;
        window.open(addressUrl, "_blank")
    };
  
    return (
        <p className="address--link" onClick={handleAddressClick}>{displayAddress}</p>
    );
  };
  
  export default Address;