import "./App.css";
import React from "react";
import {Routes, Route} from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./components/Home.js";
import Services from "./components/Services.js";
import Cabinet from "./components/Cabinet.js";
import Secteurs from "./components/Secteurs.js";
import Devis from "./components/Devis.js";
import LegalMentions from "./components/LegalMentions.js";
// services subpages
import ServicesExpertise from "./components/ServicesSubpages/ServicesExpertise";
import ServicesEntreprise from "./components/ServicesSubpages/ServicesEntreprise";
import ServicesFiscalite from "./components/ServicesSubpages/ServicesFiscalite";
import ServicesSecretariat from "./components/ServicesSubpages/ServicesSecretariat";
import ServicesAudit from "./components/ServicesSubpages/ServicesAudit";
import ServicesEvaluation from "./components/ServicesSubpages/ServicesEvaluation";
import ServicesGestion from "./components/ServicesSubpages/ServicesGestion";
// secteurs subpages
import SecteursArtisanat from "./components/SecteursSubpages/SecteursArtisanat";
import SecteursAssociation from "./components/SecteursSubpages/SecteursAssociation"
import SecteursCommerce from "./components/SecteursSubpages/SecteursCommerce";
import SecteursConsultants from "./components/SecteursSubpages/SecteursConsultants";
import SecteursHolding from "./components/SecteursSubpages/SecteursHolding";
import SecteursImmobilier from "./components/SecteursSubpages/SecteursImmobilier";
import SecteursIndustrie from "./components/SecteursSubpages/SecteursIndustrie";
import SecteursLiberales from "./components/SecteursSubpages/SecteursLiberales";
import SecteursLocations from "./components/SecteursSubpages/SecteursLocations";
import SecteursMarchands from "./components/SecteursSubpages/SecteursMarchands";
import SecteursMedical from "./components/SecteursSubpages/SecteursMedical";
import SecteursStartup from "./components/SecteursSubpages/SecteursStartup";


export default function App(){

    return(
        <>
            <Navbar />
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/services' element={<Services />} />
                <Route path='/cabinet' element={<Cabinet />} />
                <Route path='/secteurs' element={<Secteurs />} />
                <Route path='/devis' element={<Devis/>} />
                <Route path='/mentionsLegales' element={<LegalMentions/>} />

                {/* routes to services subpages */}
                <Route path='/services/expertise' element={<ServicesExpertise/>} />
                <Route path='/services/entreprise' element={<ServicesEntreprise/>} />
                <Route path='/services/fiscalite' element={<ServicesFiscalite/>} />
                <Route path='/services/secretariat' element={<ServicesSecretariat/>} />
                <Route path='/services/audit' element={<ServicesAudit/>} />
                <Route path='/services/evaluation' element={<ServicesEvaluation/>} />
                <Route path='/services/gestion' element={<ServicesGestion/>} />

                {/* routes to secteurs subpages */}
                <Route path='/secteurs/artisanat' element={<SecteursArtisanat/>} />
                <Route path='/secteurs/association' element={<SecteursAssociation/>} />
                <Route path='/secteurs/commerce' element={<SecteursCommerce/>} />
                <Route path='/secteurs/consultants' element={<SecteursConsultants/>} />
                <Route path='/secteurs/holding' element={<SecteursHolding/>} />
                <Route path='/secteurs/immobilier' element={<SecteursImmobilier/>} />
                <Route path='/secteurs/industrie' element={<SecteursIndustrie/>} />
                <Route path='/secteurs/liberales' element={<SecteursLiberales/>} />
                <Route path='/secteurs/locations' element={<SecteursLocations/>} />
                <Route path='/secteurs/marchands' element={<SecteursMarchands/>} />
                <Route path='/secteurs/medical' element={<SecteursMedical/>} />
                <Route path='/secteurs/startup' element={<SecteursStartup/>} />
            </Routes>
            <Footer />
        </>
    )
}