import React from "react"
import { Link } from "react-router-dom"

export default function SecteursHolding(){

        
    const[subMenuIsActive, setSubMenuIsActive] = React.useState(false);

    function toggleSubMenu(){
        setSubMenuIsActive(previous => !previous);
    }

     // Add a click event listener to the document to handle clicks outside of the menu
    React.useEffect(() => {

        function closeMenuOnClickOutside(event) {
            if (subMenuIsActive && !document.querySelector('.subPage--header--menu').contains(event.target)) {
                setSubMenuIsActive(false);
            }
        }

        document.addEventListener('click', closeMenuOnClickOutside);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('click', closeMenuOnClickOutside);
        };

    }, [subMenuIsActive]);

    
    return(

        <div>
            <div className="subPage--header">
                <h1>Secteurs</h1>
                <div className="subPage--header--menu" onClick={toggleSubMenu}>
                    <p>Les Secteurs</p>
                    <div className={subMenuIsActive ? "subPage--header--menu--arrow--up" : "subPage--header--menu--arrow--down"}></div>
                    <ul className={subMenuIsActive ? "subPage--header--menu--list--visible" : "subPage--header--menu--list--hidden"}>
                        <li><Link to='/secteurs/artisanat'>Artisanat</Link></li>
                        <li><Link to='/secteurs/association'>Association</Link></li>
                        <li><Link to='/secteurs/commerce'>Commerce</Link></li>
                        <li><Link to='/secteurs/consultants'>Consultants</Link></li>
                        <li><Link to='/secteurs/holding'>Holding</Link></li>
                        <li><Link to='/secteurs/immobilier'>Immobilier</Link></li>
                        <li><Link to='/secteurs/industrie'>Industrie</Link></li>
                        <li><Link to='/secteurs/liberales'>Professions liberales</Link></li>
                        <li><Link to='/secteurs/locations'>Locations meublées</Link></li>
                        <li><Link to='/secteurs/marchands'>Marchands de biens</Link></li>
                        <li><Link to='/secteurs/medical'>Médical</Link></li>
                        <li><Link to='/secteurs/startup'>Startup</Link></li>
                    </ul>
                </div>
            </div>
            
            <div className="subPage--content">
                <h2>Holding</h2>
                <p>La création de société holding demande des connaissances juridiques et fiscales.</p>
                <p>Notre cabinet prend en charge votre comptabilité tout en vous faisant bénéficier de conseils fiscaux. Nous vous aidons à créer votre holding et à faire les bons choix.</p>
                <p>Notre cabinet propose de vous accompagner de la création de la structure à la gestion de cette structure et de ses filiales, dans le suivi et le conseil fiscal, à la gestion du patrimoine et de sa transmission.</p>
            </div>
            
        </div>
        
    )

}