import React from "react"
import { Link } from "react-router-dom"

export default function ServicesSecretariat(){

    const[subMenuIsActive, setSubMenuIsActive] = React.useState(false);

    function toggleSubMenu(){
        setSubMenuIsActive(previous => !previous);
    }

     // Add a click event listener to the document to handle clicks outside of the menu
    React.useEffect(() => {

        function closeMenuOnClickOutside(event) {
            if (subMenuIsActive && !document.querySelector('.subPage--header--menu').contains(event.target)) {
                setSubMenuIsActive(false);
            }
        }

        document.addEventListener('click', closeMenuOnClickOutside);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('click', closeMenuOnClickOutside);
        };

    }, [subMenuIsActive]);
    
    return(

        <div>
            <div className="subPage--header">
                <h1>Nos Services</h1>
                <div className="subPage--header--menu" onClick={toggleSubMenu}>
                    <p>Nos Services</p>
                    <div className={subMenuIsActive ? "subPage--header--menu--arrow--up" : "subPage--header--menu--arrow--down"}></div>
                    <ul className={subMenuIsActive ? "subPage--header--menu--list--visible" : "subPage--header--menu--list--hidden"}>
                        <li><Link to="/services/expertise">Expertise Comptable</Link></li>
                        <li><Link to="/services/entreprise">Reprise et création d'entreprise</Link></li>
                        <li><Link to="/services/fiscalite">Fiscalité</Link></li>
                        <li><Link to="/services/secretariat">Secrétariat juridique</Link></li>
                        <li><Link to="/services/gestion">Gestion de la paie / assistance sociale</Link></li>
                        <li><Link to="/services/audit">Audit légal et audit contractuel</Link></li>
                        <li><Link to="/services/evaluation">Evaluation</Link></li>
                    </ul>
                </div>
            </div>
            
            <div className="subPage--content">
                <h2>Secrétariat Juridique</h2>
                <p>Notre cabinet propose son accompagnement juridique et fiscal à partir des choix à opérer lors de la constitution de votre société (impact sur les associés/dirigeants, sur le développement de l’entreprise, corrélation avec les budgets prévisionnels, etc.).</p>
                <p>Nous vous accompagnons tout au long de la vie de votre entreprise afin de respecter vos échéances légales et/ou statutaires telles que la tenue régulière d’assemblées, l’information des actionnaires, le dépôt de vos comptes annuels, ou face aux événements extraordinaires.</p>
                <p>Nous pouvons vous assister pour la réalisation des opérations suivantes :</p>
                <ul>
                    <li>Constitution de sociétés</li>
                    <li>Secrétariat juridique annuel : rédaction des documents juridiques requis pour l’approbation des comptes de votre société, prise en charge des formalités de publicité, dépôt des comptes et déclarations juridiques et fiscales liées aux distributions de dividendes, tenue des registres, etc.</li>
                    <li>Actes de la vie juridique des sociétés : transfert de siège social, extension d&#39;objet social, changement de dénomination, nomination de gouvernance, etc.</li>
                </ul>
                <p>Notre cabinet dispose d’une approche pluridisciplinaire basée sur des échanges et collaborations régulières avec des spécialistes (avocats, notaires, etc.) afin de pouvoir répondre de la manière aux opérations requérant des compétences pointues.</p>
            </div>
            
        </div>
        
    )

}