import React from "react"
import { Link } from "react-router-dom"

export default function SecteursCommerce(){

        
    const[subMenuIsActive, setSubMenuIsActive] = React.useState(false);

    function toggleSubMenu(){
        setSubMenuIsActive(previous => !previous);
    }

     // Add a click event listener to the document to handle clicks outside of the menu
    React.useEffect(() => {

        function closeMenuOnClickOutside(event) {
            if (subMenuIsActive && !document.querySelector('.subPage--header--menu').contains(event.target)) {
                setSubMenuIsActive(false);
            }
        }

        document.addEventListener('click', closeMenuOnClickOutside);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('click', closeMenuOnClickOutside);
        };

    }, [subMenuIsActive]);

    
    return(

        <div>
            <div className="subPage--header">
                <h1>Secteurs</h1>
                <div className="subPage--header--menu" onClick={toggleSubMenu}>
                    <p>Les Secteurs</p>
                    <div className={subMenuIsActive ? "subPage--header--menu--arrow--up" : "subPage--header--menu--arrow--down"}></div>
                    <ul className={subMenuIsActive ? "subPage--header--menu--list--visible" : "subPage--header--menu--list--hidden"}>
                        <li><Link to='/secteurs/artisanat'>Artisanat</Link></li>
                        <li><Link to='/secteurs/association'>Association</Link></li>
                        <li><Link to='/secteurs/commerce'>Commerce</Link></li>
                        <li><Link to='/secteurs/consultants'>Consultants</Link></li>
                        <li><Link to='/secteurs/holding'>Holding</Link></li>
                        <li><Link to='/secteurs/immobilier'>Immobilier</Link></li>
                        <li><Link to='/secteurs/industrie'>Industrie</Link></li>
                        <li><Link to='/secteurs/liberales'>Professions liberales</Link></li>
                        <li><Link to='/secteurs/locations'>Locations meublées</Link></li>
                        <li><Link to='/secteurs/marchands'>Marchands de biens</Link></li>
                        <li><Link to='/secteurs/medical'>Médical</Link></li>
                        <li><Link to='/secteurs/startup'>Startup</Link></li>
                    </ul>
                </div>
            </div>
            
            <div className="subPage--content">
                <h2>Commerce</h2>
                <p>Notre cabinet accompagne nos clients commerçants dans l’ensemble de leurs problématiques comptables, sociales, juridiques et fiscales, durant toutes les phases de leur activité (lancement, activité, cession).</p>
                <p>Notre assistance peut consister notamment à :</p>
                <ul>
                    <li>Conseil en choix de la forme juridique et leurs impacts fiscaux, sociaux, etc.</li>
                    <li>Tenir la comptabilité du traitement des pièces comptables (factures achats, ventes, banque, etc.) à la révision des comptes pour nos clients, sur site ou à distance.</li>
                    <li>Etablir une révision des comptes afin d’établir une mission de présentation des comptes annuels</li>
                    <li>Etablir pour le compte de nos clients l’ensemble des déclarations fiscales (IS, TVA, CFE, CVAE, etc.)</li>
                    <li>Etablir les bulletins de salaire et les déclarations sociales (DSN, prélèvement à la source, taxe sur les salaires, etc.) et conseiller les clients sur leurs choix en matière d’organisation</li>
                    <li>Assistance à l’organisation interne de l’entreprise</li>
                    <li>Suivi de la politique de rémunération des dirigeants, et assistance à la gestion de leurs cotisations sociales</li>
                </ul>
                <p>Exemple : distribution, activités de négoce, commerce de bouche, tabac, presse, coiffure, boulangerie, ventes sur internet, crèches et micro-crèches, etc.</p>
            </div>
            
        </div>
        
    )

}